import ReactDOM from 'react-dom';
import JavascriptTimeAgo from 'javascript-time-ago';
import App from '~/components/App';

JavascriptTimeAgo.addLocale(require('javascript-time-ago/locale/en'));

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
