import { FC, createContext, useContext, useMemo, useEffect, useState } from 'react';
import Form, { FormInstance } from 'antd/es/form';
import moment from 'moment';
import { PostFilter } from '~/types';

interface PostFilterContextValue {
  postFilterForm: FormInstance<PostFilter>;
  postFilter: PostFilter;
  setPostFilter: (filter: Partial<PostFilter>) => void;
}

const initialFilter: PostFilter = {
  tenantId: 1,
  filters: ['status:needs_review'],
  sort: 'newest',
  dates: [moment().subtract(30, 'days'), moment()],
};

const PostFilterContext = createContext<PostFilterContextValue>({
  postFilterForm: {} as any,
  postFilter: initialFilter,
  setPostFilter: () => {},
});

export const PostFilterProvider: FC = ({ children }) => {
  const [filterForm] = Form.useForm<PostFilter>();
  const [filter, setFilter] = useState<PostFilter>(initialFilter);

  useEffect(() => {
    filterForm.setFieldsValue(initialFilter);
  }, [filterForm]);

  const value = useMemo(() => ({
    postFilterForm: filterForm,
    postFilter: filter,
    setPostFilter: (values: Partial<PostFilter>) => {
      const bookId = filter.tenantId === values.tenantId ? values.bookId : ''
      setFilter({ ...filter, ...values, bookId });
      filterForm.setFieldsValue({...filter, ...values, bookId });
    },
  }), [filterForm, filter]);

  return (
    <PostFilterContext.Provider value={value}>
      {children}
    </PostFilterContext.Provider>
  );
};

export const usePostFilterContext = () => useContext(PostFilterContext);
