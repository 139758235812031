import { FC, Suspense, lazy, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { injectGlobal } from '@emotion/css';
import styled from '@emotion/styled';
import { LoginScreen } from "@yoda/components-core";
import { Alert, Layout, Spin } from 'antd';
import { PostFilterProvider } from '~/providers/PostFilterContext';
import { EnvProvider } from '~/providers/EnvContext';
import { UserProvider, useUserContext } from '~/providers/UserContext';
import { ModalProvider } from '~/components/ModalManager';
import { NavBar } from '~/components/NavBar';

import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/table/style/css';
import 'antd/es/tabs/style/css';
import 'antd/es/notification/style/css';
import 'antd/es/date-picker/style/css';
import 'antd/es/select/style/css';
import 'antd/es/grid/style/css';
import 'antd/es/divider/style/css';
import 'antd/es/space/style/css';
import 'antd/es/tooltip/style/css';
import 'antd/es/popover/style/css';
import 'antd/es/typography/style/css';
import 'antd/es/alert/style/css';

const { Header, Content } = Layout;

const DashboardPage = lazy(() => import('~/pages/Dashboard'));
const ModerationPage = lazy(() => import('~/pages/Moderation'));
const TenantsPage = lazy(() => import('~/pages/Tenants'));
const UsersPage = lazy(() => import('~/pages/Users'));

const queryClient = new QueryClient();

const AppBody: FC = () => {
  const { pathname } = useLocation();
  const { user, hasRole, hasAnyRole, azureAPI } = useUserContext();
  const [ fullPath ] = useState(window.location.href.replace(window.location.origin, ''));
 
  if (!user && pathname !== '/') {
    return <Navigate replace to="/" />;
  }

  return (
    <StyledLayout>
      <Header>
        <NavBar />
      </Header>

      <StyledContent>
        {user && !hasAnyRole() &&
          <Alert message="Access restricted." type="error" />}

        {user ? (
          <Suspense fallback={<StyledSpin size="large" />}>
            <Routes>
              {(hasRole('Admin') || hasRole('Moderator') || hasRole('Viewer')) &&
                <Route path="/dashboard" element={<DashboardPage />} />}
              {hasRole('Admin') &&
                <Route path="/tenants" element={<TenantsPage />} />}
              {hasRole('Admin') &&
                <Route path="/users" element={<UsersPage />} />}
              {(hasRole('Admin') || hasRole('Moderator')) &&
                <Route path="/moderation" element={<ModerationPage />} />}
              <Route
                path="*"
                element={<Navigate replace to={fullPath === '/' ? '/moderation' : fullPath}  />}
              />
            </Routes>
          </Suspense>
        ) : (
          <LoginScreen azureAPI={azureAPI} redirectTo={fullPath} />
        )}
      </StyledContent>
    </StyledLayout>
  );
};

const App: FC = () =>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <EnvProvider>
        <UserProvider>
          <ModalProvider>
            <PostFilterProvider>
              <AppBody />
            </PostFilterProvider>
          </ModalProvider>
        </UserProvider>
      </EnvProvider>
    </QueryClientProvider>
  </BrowserRouter>

export default App;

injectGlobal`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "segoe ui", Roboto, "helvetica neue", Arial, "noto sans", sans-serif;
    font-size: 14px;
    background: #fcfcfc;
    height: 100%;
    overflow: hidden;
  }

  #root {
    height: 100%;
  }
`;

const StyledLayout = styled(Layout)`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  flex-grow: 1;
  overflow: auto scroll;
  padding: 20px 0;
`;

const StyledSpin = styled(Spin)`
  width: 100%;
  text-align: center;
  margin: 20px auto;
`;
