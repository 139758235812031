import { FC, createContext, useContext, useMemo, useState } from 'react';

interface ModalParams<T> {
  Component: FC<T>;
  props: T;
}

interface ModalContextValue {
  showModal: <T>(Component: FC<T>, props: T) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextValue>({
  showModal: () => {},
  closeModal: () => {},
});

export const ModalProvider: FC = ({ children }) => {
  const [modalParams, setModalParams] = useState<ModalParams<any> | null>(null);

  const value = useMemo(() => ({
    showModal(Component: FC<any>, props: any) {
      setModalParams({ Component, props });
    },
    closeModal() {
      setModalParams(null);
    },
  }), []);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {!!modalParams && <modalParams.Component {...modalParams.props} />}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
