import { FC } from 'react';
import { css } from '@emotion/css';
import Select from 'antd/es/select';
import { useEnvContext } from '~/providers/EnvContext';

export const EnvSelect: FC = () => {
  const { env, envs, changeEnv } = useEnvContext();
  const options = envs.map(env => ({ value: env, label: env }));
  return <Select className={selectStyle} options={options} value={env} onChange={changeEnv} />;
};

const selectStyle = css`
  width: 100px;
  margin-right: 40px;
`;
