import { FC, createContext, useContext, useMemo, useState, memo } from 'react';
import { Environment } from '~/services/apiRequest';

const envs: Environment[] = [
  'dev',
  'qa',
  'stage',
  'prod',
];

interface EnvContextValue {
  env: Environment;
  envs: Environment[];
  changeEnv: (env: Environment) => void;
}

const EnvContext = createContext<EnvContextValue>({
  env: 'prod',
  envs,
  changeEnv: () => {},
});

export const EnvProvider: FC = memo(({ children }) => {
  const [env, setEnv] = useState<Environment>('prod');

  const value = useMemo(() => ({
    env,
    envs,
    changeEnv: setEnv,
  }), [env]);

  return (
    <EnvContext.Provider value={value}>
      {children}
    </EnvContext.Provider>
  );
});

export const useEnvContext = () => useContext(EnvContext);
