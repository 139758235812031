import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Space } from 'antd';
import { SITE_TITLE } from '~/contstants';
import { useUserContext } from '~/providers/UserContext';
import { EnvSelect } from '~/components/EnvSelect';

export const NavBar: FC = () => {
  const { user, hasRole, login, logout } = useUserContext();

  return (
    <Container>
      <Title>{SITE_TITLE}</Title>

      <Nav>
        {(hasRole('Admin') || hasRole('Moderator') || hasRole('Viewer')) &&
          <Link to="/dashboard">Dashboard</Link>}
        {hasRole('Admin') &&
          <Link to="/tenants">Tenants</Link>}
        {hasRole('Admin') &&
          <Link to="/users">Users</Link>}
        {(hasRole('Admin') || hasRole('Moderator')) &&
          <Link to="/moderation">Moderation</Link>}
      </Nav>
      <div style={{flexGrow:1}}></div>

      <Space>
        <EnvSelect />
        {user
          ? <LoginButton type="link" onClick={logout}>Logout</LoginButton>
          : <LoginButton type="link" onClick={login}>Login</LoginButton>}
      </Space>
    </Container>
  )
};

const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #05112a;
  box-shadow: 0 1px 4px grey;
`;

const Title = styled.div`
  font-weight: bold;
  color: #e8ecf7;
  padding: 0 10px;
  margin: 0 10px;
  border-right: 1px solid #ccc;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
`;

const Link = styled(NavLink)`
  color: #e8ecf7;
  padding: 15px;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: white;
  }

  &.active {
    background: #8d44ad;
  }
`;

const LoginButton = styled(Button)`
  color: white;

  &:hover {
    color: white;
  }
`;
