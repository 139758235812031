import { RejectReason, ReviewStatus } from "./types";

export const SITE_TITLE = 'Social Admin';

export const rejectReasonNames: Record<RejectReason, string> = {
  [RejectReason.general]: 'General',
  [RejectReason.offensive]: 'Offensive',
  [RejectReason.not_relevant]: 'Not relevant',
  [RejectReason.rights_violation]: 'Contains violations of rights and permissions',
  [RejectReason.autoreject]: 'Auto rejected',
};

export const reviewStatusNames: Record<ReviewStatus, string> = {
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Reject',
  needs_review: 'Needs review',
};
