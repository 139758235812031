import { Moment } from 'moment';

export interface ItemCollection<T> {
  items: T[];
  count: number;
}

export interface BookSubLink {
  id: string;
  bookName: string;
}

export interface BookLink extends BookSubLink {
  bookId?: string;
}

export interface Link {
  id: string;
  order?: number;
  subLink?: Link;
}

export interface Tenant {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export type TenantInput = Pick<Tenant, 'name'>;

export type ReviewStatus = 'pending' | 'approved' | 'rejected' | 'needs_review';

export enum UserType {
  student = 'student',
  tutor = 'tutor'
}

export interface Attachment {
  name: string;
  type: string;
  url: string;
}

export interface PostContent {
  html?: string;
  video?: {
    url: string;
  };
  attachments?: Attachment[];
}

export interface Post {
  id: number;
  userId: string;
  user?: UserData;
  rootId: number | null;
  parentId: number | null;
  title: string | null;
  content: PostContent;
  link: Link;
  score: number;
  reviewStatus: ReviewStatus;
  rejectReason: RejectReason | null;
  reviewedBy: string | null;
  reportsCount: number;
  repliesCount: number | null;
  deleted: boolean;
  createdAt: string;
}

export interface PostFilter {
  tenantId: number;
  bookId?: string;
  subjectId?: string;
  dates?: [Moment, Moment];
  filters: string[];
  sort: 'newest' | 'oldest' | 'most-ranked' | 'most-replied';
  pageSize?: number;
  page?: number;
}

export interface UserData {
  givenName?: string;
  familyName?: string;
  userName?: string;
  email?: string;
}

export interface PostsCountByStatus {
  reviewStatus: ReviewStatus;
  _count: number;
}

interface Avatar {
  startColor?: string;
  endColor?: string;
  initials?: string;
  image?: string;
}

export interface ExtendedUserData  {
  id?: string
  score?: number
  type?: UserType
  autorejectPosts?: boolean
  createdAt?: Date
  updatedAt?: Date
  givenName?: string;
  familyName?: string;
  homeCountryCode?: string;
  userName?: string;
  avatar?: Avatar;
  email?: string;
  postCount: PostsCountByStatus[];
}

export enum RejectReason {
  general = 'general',
  offensive = 'offensive',
  not_relevant = 'not_relevant',
  rights_violation = 'rights_violation',
  autoreject = 'autoreject',
}

export enum TenantId {
  etext = 1,
  study = 2,
}
