import { FC, createContext, useContext, useMemo, useState, memo, useEffect, useCallback } from 'react';
import { LoginAPI, LoginUserContext } from '@yoda/components-core';
import { User } from '@yoda/components-core/lib/AzureLogin/types';

type AdminRole = 'Admin' | 'Viewer' | 'Moderator';

interface UserContextValue {
  user: User | null;
  azureAPI: LoginAPI;
  hasRole: (role: AdminRole) => boolean;
  hasAnyRole: () => boolean;
  login: () => void;
  logout: () => void;
}

const UserContext = createContext<UserContextValue>({
  user: null,
  azureAPI: new LoginAPI(),
  hasRole: () => false,
  hasAnyRole: () => false,
  login: () => {},
  logout: () => {},
});

export const UserProvider: FC = memo(({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [azureAPI] = useState(() => new LoginAPI());

  const roles = useMemo(() => {
    console.log('idTokenClaims', user?.idTokenClaims);
    return user?.idTokenClaims?.roles || [];
  }, [user]);

  const hasRole = useCallback((role: string): boolean => {
    return !!roles.includes(role);
  }, [roles]);

  const value = useMemo<UserContextValue>(() => {
    const updatedUser = user
      ? {
        ...user,
        refreshTokenCallback: (_unusedUserArg: User, callback: any) => { // NOTE: `_unusedUserArg` has modified `refreshTokenCallback`, do not use it here
          user.refreshTokenCallback?.(user, (token: string) => {
            setUser({ ...user, token });
            callback(token);
          });
        },
      }
      : null;

    return {
      user: updatedUser,
      azureAPI,
      hasRole,
      hasAnyRole: () => !!roles.length,
      login: () => {
        azureAPI.login('loginPopup', setUser).catch(console.error);
      },
      logout: () => {
        azureAPI.logout().catch(console.error);
      },
    };
  }, [user, roles, hasRole, azureAPI]);

  return (
    <UserContext.Provider value={value}>
      <LoginUserContext.Provider value={{ user, setUser }}>
        {children}
      </LoginUserContext.Provider>
    </UserContext.Provider>
  );
});

export const useUserContext = () => useContext(UserContext);
